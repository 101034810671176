import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const SEND_LOCALES = {
	WOLTAIR_CZ: 'odeslano',
	WOLTAIR_PL: 'dziekuje',
	WOLTAIR_DE: 'danke',
	WOLTAIR_IT: 'grazie'
}

export const SEND = SEND_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return SEND === param
}) satisfies ParamMatcher
