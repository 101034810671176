import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const FEEDBACK_LOCALES = {
	WOLTAIR_CZ: 'feedback',
	WOLTAIR_PL: 'feedback',
	WOLTAIR_DE: 'feedback',
	WOLTAIR_IT: 'feedback'
}

export const FEEDBACK = FEEDBACK_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return FEEDBACK === param
}) satisfies ParamMatcher
