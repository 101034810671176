import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const CMS_PREVIEW_LOCALES = {
	WOLTAIR_CZ: 'preview',
	WOLTAIR_PL: 'preview',
	WOLTAIR_DE: 'preview',
	WOLTAIR_IT: 'preview'
}

export const CMS_PREVIEW = CMS_PREVIEW_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return CMS_PREVIEW === param
}) satisfies ParamMatcher
