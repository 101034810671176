import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const GAS_BOILERS_LOCALES = {
	WOLTAIR_CZ: 'plynove-kotle',
	WOLTAIR_PL: 'kotly-gazowe'
}

export const GAS_BOILERS = GAS_BOILERS_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return GAS_BOILERS === param
}) satisfies ParamMatcher
