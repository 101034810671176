import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const REGISTERED_LOCALES = {
	WOLTAIR_CZ: 'prihlaseno',
	WOLTAIR_PL: 'zalogowany',
	WOLTAIR_DE: 'prihlaseno',
	WOLTAIR_IT: 'prihlaseno'
}

export const REGISTERED = REGISTERED_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return REGISTERED === param
}) satisfies ParamMatcher
