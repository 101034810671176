import { REGIONS } from '$lib/consts'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

export const MATCH_REGION = (param) => {
	const result = REGIONS[PUBLIC_WOLTAIR_WEB_CODE].find((r) => r.url === param)
	return !!result?.name
}

export const match = ((param) => {
	return MATCH_REGION(param)
}) satisfies ParamMatcher
