import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const CONSTRUCTION_LOCALES = {
	WOLTAIR_CZ: 'montaz',
	WOLTAIR_PL: 'montaz',
	WOLTAIR_DE: 'montage',
	WOLTAIR_IT: 'montaggio'
}

export const CONSTRUCTION = CONSTRUCTION_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return CONSTRUCTION === param
}) satisfies ParamMatcher
