import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const WOLTAIR_SELF_SERVICE_LOCALES = {
	WOLTAIR_CZ: null,
	WOLTAIR_PL: null,
	WOLTAIR_DE: null,
	WOLTAIR_IT: null
}

export const WOLTAIR_SELF_SERVICE =
	WOLTAIR_SELF_SERVICE_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return WOLTAIR_SELF_SERVICE === param
}) satisfies ParamMatcher
