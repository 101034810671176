import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const NEW_PAYMENT_LOCALES = {
	WOLTAIR_CZ: 'nova-platba',
	WOLTAIR_PL: 'platnosc-nova',
	WOLTAIR_DE: 'nova-platba',
	WOLTAIR_IT: 'nova-platba'
}

export const NEW_PAYMENT = NEW_PAYMENT_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return NEW_PAYMENT === param
}) satisfies ParamMatcher
