import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const EXTENDED_WARRANTY_LOCALES = {
	WOLTAIR_CZ: 'prodlouzena-zaruka',
	WOLTAIR_PL: 'rozszerzona-gwarancja',
	WOLTAIR_DE: null,
	WOLTAIR_IT: 'garanzia-w10'
}

export const EXTENDED_WARRANTY = EXTENDED_WARRANTY_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return EXTENDED_WARRANTY === param
}) satisfies ParamMatcher
